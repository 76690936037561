<template>
  <div class="page">
    <div class="dkzc-box">
      <span class="title">在线学习</span>
      <div class="analogVolume">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-- 学习资料 -->
          <el-tab-pane label="学习资料" name="learningMaterials">
            <nav v-loading="navLoading">
              <el-menu
                :default-active="ComponentsIndex"
                unique-opened
                active-text-color="#03DBC6"
                @select="
                  ComponentsIndex == $event
                    ? (ComponentsIndex = '0')
                    : (ComponentsIndex = $event)
                "
              >
                <!-- <template v-for="(navItem, navIndex) in navList">
                  <el-submenu
                    v-if="navItem.childList"
                    :key="navIndex"
                    :index="`${navIndex}`"
                  >
                    <template slot="title">
                      <i :class="navItem.icon"></i>
                      <span>{{ navItem.label }}</span>
                    </template>
                    <template
                      v-for="(childNavItem, childNavIndex) in navItem.childList"
                    >
                      <el-menu-item
                        :key="childNavIndex"
                        :index="`${navIndex}-${childNavIndex}`"
                        >{{ childNavItem.label }}</el-menu-item
                      >
                    </template>
                  </el-submenu>
                  <el-menu-item v-else :key="navIndex" :index="`${navIndex}`">
                    <i :class="navItem.icon"></i>
                    <span slot="title">{{ navItem.label }}</span>
                  </el-menu-item>
                </template> -->

                <div
                  v-for="(item, index) in directoryList"
                  :key="index"
                  class="navList"
                  @click="navChangeTab(index, item)"
                  :title="item.name"
                  :class="index == navIndex ? 'active' : ''"
                >
                  {{ item.name }}
                </div>
              </el-menu>
              <el-tree
                v-if="ComponentsIndex !== '0'"
                class="filter-tree"
                :data="treeData"
                :props="{
                  children: 'children',
                  label: 'label',
                }"
                default-expand-all
                ref="tree"
              >
              </el-tree>
            </nav>
            <div class="personal" v-if="userInfo.id">
              <!-- 个人信息 -->
              <div class="information">
                <img
                  class="avatar"
                  :src="
                    doctorInfo.doctor.photo
                      ? doctorInfo.doctor.photo
                      : require('@image/default.png')
                  "
                />
                <div class="information-right">
                  <div class="right-one">
                    <span>{{ doctorInfo.doctor.name }}</span>
                    <span class="jobTitle">{{
                      doctorInfo.doctor.title_name
                    }}</span>
                  </div>
                  <div class="right-two">
                    <span class="address">{{ doctorInfo.doctor.company }}</span>
                    <span>{{ doctorInfo.doctor.department_name }}</span>
                  </div>
                  <div class="right-three">
                    <span>学习科目：</span>
                    <span
                      v-for="(item, index) in doctorInfo.subject"
                      :key="index"
                      ><span v-if="index > 0">+</span>{{ item.name }}</span
                    >
                  </div>
                </div>
              </div>
              <!-- 学习选项卡 -->
              <div class="profession">
                <span
                  v-for="(item, index) in doctorInfo.subject"
                  :key="index"
                  @click="changeTab(index)"
                  :class="index == professionalIndex ? 'active' : ''"
                  >{{ item.name }}</span
                >

                <!-- <span class="name">内分泌专业</span> -->
                <!-- <span v-for="(item, index) in 7" :key="index">内分泌专业</span> -->
              </div>
            </div>
            <!-- 灰色间隔 -->
            <div class="interval"></div>
            <!-- 学习内容 -->
            <div class="content" v-loading="navLoading">
              <div class="professionalContent">
                <!-- <span class="content-title">第一部分</span> -->
                <span class="learn-title">{{ detail.catalog_name }}</span>
                <!-- <span class="chapter">第一章 概述</span> -->
                <span class="subTitle" v-html="detail.content"> </span>
              </div>
              <div class="video" v-if="detail.video">
                <div class="img" @click="openVideo">
                  <img :src="detail.video.avatar" />
                  <i class="el-icon-caret-right"></i>
                  <!-- <span>12:11</span> -->
                </div>
              </div>
            </div>
          </el-tab-pane>

          <!-- 学习计划 -->
          <el-tab-pane label="学习计划" name="studyPlan">
            <!-- 编辑弹窗 -->
            <el-button
              type="text"
              @click="studyPlanAdd"
              class="studyPlan-button"
              >新增</el-button
            >

            <el-dialog
              :visible.sync="dialogVisible"
              width="900px"
              :before-close="handleClose"
            >
              <div class="titleInput">
                <input
                  autocomplete="off"
                  readonly
                  onfocus="this.removeAttribute('readonly');"
                  type="text"
                  placeholder="请输入要学习的课程名称"
                  class="titleOne"
                  v-model="title"
                  style="color: #333 !important"
                />
                <textarea
                  placeholder="请输入具体学习课程的内容"
                  v-model="content"
                  style="color: #333 !important"
                ></textarea>
                <span
                  class="textButton"
                  @click="getPlan"
                  :v-loadding="selectStatus"
                  >确定</span
                >
              </div>
            </el-dialog>
            <div class="personal">
              <div class="personal-studyPlan">
                <!-- 日历 -->
                <el-calendar>
                  <template slot="dateCell" slot-scope="{ date, data }">
                    <div
                      class="selectday"
                      @click.stop="selectDay(data)"
                      :class="{ selectdd: data.day == time }"
                    >
                      <div class="selecss">
                        {{ Number(data.day.split("-")[2]) }}
                      </div>
                      <div
                        class="selectCss"
                        :class="{ selectddC: data.day == time }"
                        v-show="holiday.hasOwnProperty(data.day)"
                      >
                        .
                      </div>
                    </div>
                  </template>
                </el-calendar>

                <div class="studyPlan-right">
                  <div
                    class="right-box"
                    v-for="(item, index) in stydyPlan"
                    :key="index"
                  >
                    <div class="right-top">
                      <span>{{ item.title }}</span>
                      <el-dropdown trigger="click">
                        <i class="iconfont shenglvehao"></i>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item @click.native="updataPlan(item)"
                            >编辑
                          </el-dropdown-item>
                          <el-dropdown-item @click.native="delPlan(item.id)"
                            >删除
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    <span class="right-subTitle">{{ item.content }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <!-- 学习轨迹 -->
          <el-tab-pane
            label="学习轨迹"
            name="learningTrack"
            v-loading="navLoading"
          >
            <div class="personal">
              <div class="learningTrack-one" v-if="userInfo.id">
                <div class="learningTrackLeft">
                  <img
                    class="learningTrack-avatar"
                    :src="
                      doctorInfo.doctor.photo
                        ? doctorInfo.doctor.photo
                        : require('@image/default.png')
                    "
                  />
                  <div class="learningTrack-right">
                    <span>学习时长</span>
                    <span class="learningTrack-time"
                      >{{ timeTotal[0] }}′ {{ timeTotal[1] }}″</span
                    >
                  </div>
                </div>

                <!-- 进度条 -->
                <div class="progressBarBox">
                  <div
                    class="progressBar"
                    v-for="(item, index) in markList"
                    :key="index"
                  >
                    <el-progress
                      type="circle"
                      color="#03DBC6"
                      :percentage="item.precent"
                    >
                    </el-progress>
                    <span class="progressBar-profession">{{ item.name }}</span>
                  </div>
                </div>
              </div>

              <!-- 学习轨迹详情 -->
              <div class="learningTrack-course">
                <div
                  class="course-content"
                  v-for="(item, index) in markList"
                  :key="index"
                >
                  <span class="content-title">{{ item.name }}</span>
                  <div
                    class="courseDetails"
                    @click="goDetw(item, it)"
                    v-for="(it, i) in item.catalog"
                    :key="i"
                  >
                    <div class="border" :class="{ border1: it.study_status }">
                      <div
                        class="border-round"
                        :class="{ 'border-round1': it.study_status }"
                      ></div>
                    </div>
                    <span class="courseDetails-time">{{ it.create_time }}</span>
                    <span>{{ it.name.trim().split(/\s+/)[1] }}</span>
                    <span class="point"
                      >…………………………………………………………………………………………………………………………………………………………………………………………………………</span
                    >
                    <span
                      ><span v-if="it.hour[0] != '00'">{{ it.hour[0] }}′</span>
                      {{ it.hour[1] }}″</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <!-- 视频弹窗 -->
    <el-dialog
      custom-class="video"
      width="890px"
      :visible.sync="showVideo"
      v-if="detail.video"
    >
      <div class="turnOff" @click="showVideo = !showVideo">
        <i class="iconfont guanbi"></i>
      </div>
      <video
        ref="videoPlayer"
        controls
        src=""
        controlslist="nodownload"
        style="width: 890px; height: 560px"
      ></video>
      <span>{{ videoTitle }}</span>
    </el-dialog>
  </div>
</template>

<script>
// import { createLogger, mapState } from "vuex";
import { mapState } from "vuex";

export default {
  data() {
    return {
      navLoading: false,
      dialogVisible: false,
      activeName: "learningMaterials",
      //学习时间表
      holiday: {
        // '2021-04-20': ['放假'],
        // '2021-03-21': ['放11假'],
        // '2021-03-22': ['放13213211假'],
      },
      //学习时间表
      holiday1: {
        "2021-04-20": ["放假"],
        // '2021-03-21': ['放11假'],
        // '2021-03-22': ['放13213211假'],
      },
      stydyPlan: [], //某天的学习计划表
      navList: [
        {
          label: "基础理论篇",
          childList: [
            {
              label: "第一部分  解剖学",
            },
            {
              label: "第二部分  生理学",
            },
            {
              label: "第三部分  生物化学",
            },
            {
              label: "第四部分  病理学",
            },
          ],
        },
        {
          label: "基本技能篇",
          childList: [
            {
              label: "第一部分  解剖学",
            },
            {
              label: "第二部分  生理学",
            },
            {
              label: "第三部分  生物化学",
            },
            {
              label: "第四部分  病理学",
            },
          ],
        },
        {
          label: "基础知识篇",
          childList: [
            {
              label: "第一部分  解剖学",
            },
            {
              label: "第二部分  生理学",
            },
            {
              label: "第三部分  生物化学",
            },
            {
              label: "第四部分  病理学",
            },
          ],
        },
        {
          label: "基础知识篇",
          childList: [
            {
              label: "第一部分  解剖学",
            },
            {
              label: "第二部分  生理学",
            },
            {
              label: "第三部分  生物化学",
            },
            {
              label: "第四部分  病理学",
            },
          ],
        },
      ],
      ComponentsIndex: "0",
      treeData: [
        {
          id: 1,
          label: "第一章 概念",
          children: [
            {
              id: 4,
              label: "一、基本概念",
            },
            {
              id: 5,
              label: "二、解剖学姿势",
            },
            {
              id: 6,
              label: "三、常用方位术语",
            },
            {
              id: 7,
              label: "四、轴和面",
            },
          ],
        },
        {
          id: 2,
          label: "第2章  运动系统",
          children: [
            {
              id: 8,
              label: "一、骨",
            },
            {
              id: 9,
              label: "二、关节",
            },
          ],
        },
        {
          id: 3,
          label: "第3章  消化系统",
          children: [
            {
              id: 10,
              label: "一、消化管",
            },
            {
              id: 11,
              label: "二、消化腺",
            },
            {
              id: 12,
              label: "二、腹膜",
            },
          ],
        },
      ],
      showVideo: false,
      time: "",
      title: "",
      content: "",
      studyId: null,
      selectStatus: false,
      doctorInfo: {
        doctor: {},
      },
      professionalIndex: 0,
      directoryList: [],
      book_uid: "",
      catalog_uid: "",
      navIndex: 0,
      detail: {
        video: {},
      },
      videoTitle: "",
      timeTotal: 0,
      markList: [],
      studyTime: 0,
      studyCatalog_uid: 0,
      dt: {
        day: Date.parse(new Date()),
      },
      show: true,
      timeout: -1,
    };
  },
  watch: {
    async activeName(val, v) {
      if (v == "learningMaterials" && val != "learningMaterials") {
        let res = await this.$newsApi.addStudyMark({
          catalog_uid: this.studyCatalog_uid,
          time: this.studyTime,
        });
        if (res.code == 200) {
          this.studyTime = 0;
          clearInterval(this.inter);
        }
      } else if (val == "learningMaterials") {
        this.daoji();
      }
    },
    studyTime(val) {
      if (val === 0) this.show = true;
    },
  },
  async destroyed() {
    if (this.studyTime) {
      let res = await this.$newsApi.addStudyMark({
        catalog_uid: this.studyCatalog_uid,
        time: this.studyTime,
      });
      if (res.code == 200) {
        this.studyTime = 0;
      }
    }
    clearInterval(this.inter);
    window.removeEventListener("scroll", this.q2wedad);
  },
  async created() {
    this.xin.isLoadding(true);
    this.dt.day = this.timestampToTime(this.dt.day / 1020);
    this.selectDay(this.dt);
    await this.getStudyData();
    // let {book_uid,catalog_uid} = this.$route.query;
    // if(book_uid&&catalog_uid){
    //     this.book_uid = book_uid;
    //     this.catalog_uid = catalog_uid;
    //     let index = this.directoryList.findIndex(v=>v.catalog_uid==catalog_uid);
    //     let in2 = this.doctorInfo.subject.findIndex(v=>v.catalog_uid==catalog_uid)
    //     if(index!=-1){
    //         this.navIndex = index;
    //         this.professionalIndex = in2;
    //     }
    // }
    if (this.doctorInfo.subject.length) {
      let book_uid;
      if (this.$route.query.name) {
        for (let i in this.doctorInfo.subject) {
          if (this.doctorInfo.subject[i].name == this.$route.query.name) {
            this.professionalIndex = i;
            break;
          }
        }
        book_uid = this.$route.query.book_uid;
      } else {
        book_uid = this.doctorInfo.subject[0].book_uid;
      }

      await this.getBookCatalog(book_uid);
      this.daoji();
      await this.getBookInfo(book_uid, this.catalog_uid);
    } else {
      this.xin.message("请至考纲教材添加书籍");
    }
    this.xin.isLoadding(false);
  },
  mounted() {
    window.addEventListener("scroll", this.q2wedad);
  },
  methods: {
    daoji() {
      this.inter = setInterval(() => {
        this.studyTime++;
      }, 1020);
    },
    studyPlanAdd() {
      if (
        new Date(this.timestampToTime(new Date() / 1020)).getTime() >
        new Date(this.time).getTime()
      ) {
        this.$message({
          message: "不能选中当天之前的时间",
          type: "error",
        });
        return;
      } else {
        this.dialogVisible = true;
      }
    },
    async q2wedad() {
      if (
        this.show &&
        this.activeName == "learningMaterials" &&
        this.detail.content
      )
        if (
          document.documentElement.scrollHeight -
            document.documentElement.scrollTop -
            document.documentElement.clientHeight <
          733
        ) {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(async () => {
            if (this.studyTime) {
              let res = await this.$newsApi.addStudyMark({
                catalog_uid: this.studyCatalog_uid,
                time: this.studyTime,
                status: 1,
              });
              if (res.code == 200) {
                this.show = false;
                this.studyTime = 1;
              }
            }
          }, 3000);
        }
    },
    async goDetw(item, it) {
      this.activeName = "learningMaterials";
      for (let i in this.doctorInfo.subject) {
        if (this.doctorInfo.subject[i].name == item.name) {
          this.professionalIndex = i;
          break;
        }
      }
      await this.getBookCatalog(item.book_uid);
      for (let j in this.directoryList) {
        if (this.directoryList[j].catalog_uid == it.catalog_uid) {
          this.navIndex = j;
          break;
        }
      }
      await this.getBookInfo(item.book_uid, it.catalog_uid);
    },

    async getStudyMark() {
      let res = await this.$newsApi.getStudyMark();
      if (res.code == 200) {
        this.timeTotal = 0;
        this.markList = res.data.lists;
        for (let i in this.markList) {
          let num = 0;
          let total = 0;
          let length = 0;
          let catalog = this.markList[i].catalog;
          for (let j in catalog) {
            catalog[j].create_time = this.$moment(catalog[j].create_time)
              .format()
              .substring(5, 10);
            num += catalog[j].time;
            total += catalog[j].status;
            length = catalog.length;
            catalog[j].hour = this.s_to_hs(catalog[j].time).split(":");
          }
          this.markList[i].timeTotal = num;
          this.markList[i].precent = total == 0 ? 0 : (total / length) * 100;
          this.timeTotal += this.markList[i].timeTotal;
        }
        this.timeTotal = String(this.s_to_hs(this.timeTotal)).split(":");
      } else {
        this.xin.message(res.message);
      }
    },

    // 获取书籍信息
    async getBookInfo(book_uid, catalog_uid) {
      this.studyTime = 0;
      let res = await this.$newsApi.getBookInfo({
        book_uid: book_uid,
        catalog_uid: catalog_uid,
      });
      if (res.code == 200) {
        this.detail = res.data;
        this.studyCatalog_uid = this.detail.catalog_uid;
      } else {
        this.xin.message(res.message);
      }
    },

    // 侧边栏目录的点击
    async navChangeTab(index, item) {
      let res = await this.$newsApi.addStudyMark({
        catalog_uid: this.studyCatalog_uid,
        time: this.studyTime,
      });
      if (res.code == 200) {
        this.studyTime = 0;
      }
      this.navLoading = true;
      this.videoTitle = item.name;
      this.navIndex = index;
      await this.getBookInfo(item.book_uid, item.catalog_uid);
      this.navLoading = false;
    },

    async getBookCatalog(book_uid) {
      let res = await this.$newsApi.getBookCatalog({
        book_uid: book_uid,
      });
      if (res.code == 200) {
        this.directoryList = res.data;
        this.videoTitle = this.directoryList[0].name;
        this.catalog_uid = this.directoryList[0].catalog_uid;
      } else {
        this.xin.message(res.message);
      }
    },

    async changeTab(index) {
      let res = await this.$newsApi.addStudyMark({
        catalog_uid: this.studyCatalog_uid,
        time: this.studyTime,
      });
      if (res.code == 200) {
        this.studyTime = 0;
      }
      this.navLoading = true;
      this.professionalIndex = index;
      await this.getBookCatalog(this.doctorInfo.subject[index].book_uid);

      await this.navChangeTab(0, this.directoryList[0]);

      // directoryList

      this.navLoading = false;
    },

    async getStudyData() {
      let res = await this.$newsApi.getStudyData();
      if (res.code == 200) {
        this.doctorInfo = res.data;
        if (this.doctorInfo.subject.length) {
          this.book_uid = this.doctorInfo.subject[0].book_uid;
        }
      } else {
        if (res.code != "-40000") {
          this.xin.message(res.message);
        }
      }
    },
    //所有的学习计划
    async getAllPlan() {
      let res = await this.$learningApi.getAllStudyPlan();
      let arr = [];
      res.data.forEach((item) => {
        let da = this.timestampToTime(item.time);
        arr[da] = [item.title];
      });
      this.holiday = arr;
      this.$forceUpdate();
    },
    //添加修改学习计划
    async getPlan() {
      if (!this.time) {
        this.$message({
          message: "请选择时间",
          type: "info",
        });
        return;
      }

      if (!this.title) {
        this.$message({
          message: "请输入要学习的课程名称",
          type: "info",
        });
        return;
      }

      if (!this.content) {
        this.$message({
          message: "请输入具体学习课程的内容",
          type: "info",
        });
        return;
      }

      if (this.selectStatus == true) {
        return;
      }
      this.selectStatus = true;

      let res = await this.$learningApi.getStydyPlan({
        title: this.title,
        content: this.content,
        study_id: this.studyId,
        time: this.time,
      });
      if (res.code === 200) {
        this.dialogVisible = false;
        if (this.dialogVisible == false) {
          this.selectStatus = false;
        }
        this.clearInfo();
        await this.getStudyPlan();
        await this.getAllPlan();
      } else {
        this.$message.error(res.message);
        this.selectStatus = false;
      }
    },
    //获取修改学习内容
    updataPlan(it) {
      if (it) {
        this.title = it.title;
        this.content = it.content;
        this.studyId = it.id;
        // this.time = it.create_time
        this.dialogVisible = true;
      }
    },
    //删除学习计划
    async delPlan(el) {
      let res = await this.$learningApi.getDelPlan({
        study_id: el,
      });
      if (res.code === 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getStudyPlan();
        this.getAllPlan();
      }
    },
    //获得指定日期的学习计划
    async getStudyPlan() {
      let res = await this.$learningApi.getPlan({
        time: this.time,
      });
      if (res.code === 200) {
        this.stydyPlan = res.data;
      }
    },
    async handleClick(tab, event) {
      if (tab.name == "learningTrack") {
        this.navLoading = true;
        await this.getStudyMark();
        this.navLoading = false;
      } else if (tab.name == "studyPlan") {
        await this.getAllPlan();
      }
      if (!this.userInfo.id) {
        this.$router.push("/index");
      }
    },
    handleClose(done) {
      this.title = "";
      this.content = "";
      done();
    },
    //获取点击时间
    async selectDay(data) {
      this.time = data.day;
      await this.getStudyPlan();
    },
    //清除信息
    clearInfo() {
      this.title = "";
      this.content = "";
      this.studyId = "";
    },
    //时间转换
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000);
      let Y = date.getFullYear(); //+'年'
      let M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1; //+'月'
      let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(); // +'日'
      // let h = date.getHours() + ":";
      // let m = date.getMinutes() + ":";
      // let s = date.getSeconds();
      return Y + "-" + M + "-" + D; //时分秒可以根据自己的需求加上
    },
    s_to_hs(s) {
      //计算分钟
      //算法：将秒数除以60，然后下舍入，既得到分钟数
      var h;
      h = Math.floor(s / 60);
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60;
      //将变量转换为字符串
      h += "";
      s += "";
      //如果只有一位数，前面增加一个0
      h = h.length == 1 ? "0" + h : h;
      s = s.length == 1 ? "0" + s : s;
      return h + ":" + s;
    },

    // 打开视频
    openVideo() {
      this.showVideo = true;
      this.$nextTick(() => {
        let video = this.$refs.videoPlayer;
        video.src = this.detail.video.path;
        // 开启画中画时关闭大播放器
        video.addEventListener("enterpictureinpicture", () => {
          this.showVideo = false;
        });
      });
    },
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

::v-deep {
  .el-message-box {
    width: 900px !important;
  }

  .el-progress-circle {
    width: 84px !important;
    height: 84px !important;
  }

  .el-progress__text {
    font-size: 18px !important;
    top: 35px;
  }

  .el-dropdown {
    margin-left: auto;
    cursor: pointer;
  }

  .el-tabs__content {
    overflow: unset;
  }

  .video {
    border-radius: 0;

    > .el-dialog__body {
      > video {
        width: 890px;
        height: 560px;
      }

      > span {
        height: 65px;
        background-color: white;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        font-size: 18px;
        color: #333333;
        padding: 0 30px;
      }
    }
  }
}

:：v-deep.el-calendar-day>div  {
  height: 100%;
}

.selectday {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.selectdd {
  border-radius: 50%;
  border: 2px solid #1abdc3;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 38px;
  height: 38px;
}

.selecss {
  text-align: center;
  position: relative;
  font-size: 20px;
}

.selectCss {
  top: 14px;
  font-size: 40px;
  position: absolute;
  color: #1abdc3;
  display: flex;
  justify-content: center;
}

.selectddC {
  top: 8px;
}

::v-deep .el-calendar {
  width: 400px;
  margin-right: 20px;
}

::v-deep .el-calendar-table .el-calendar-day {
  height: 50px;
  width: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .el-calendar-day {
  padding: 0px;
}

/deep/ .el-calendar-table {
  width: 350px;
}

::v-deep .el-calendar-table td.is-selected {
  background-color: #ffffff;
}

::v-deep .el-tabs__header {
  margin-bottom: 0;

  .el-tabs__nav-scroll {
    padding-left: 20px;
    height: 59px;

    .el-tabs__nav {
      height: 100%;
      line-height: 59px;
    }

    .is-active {
      color: $--themeColor;
      transform: scale(1);
      border: none !important;
      box-shadow: none !important;
    }

    .el-tabs__header {
      font-size: 24px;
    }
  }
}

.turnOff {
  position: relative;
  z-index: 5;

  > .guanbi {
    cursor: pointer;
    font-size: 18px;
    color: #333;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

nav {
  position: absolute;
  z-index: 1;
  left: -186px;
  // left: -13vw;
  height: 100%;
  display: flex;
  box-sizing: border-box;

  // margin-left:2vw;
  .navList {
    width: 165px;
    height: 60px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 60px;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &.active {
      color: $--themeColor;
    }
  }

  ::v-deep {
    > .el-menu {
      border: 1px solid #d9d9d9;
      border-right: none;
      height: max-content;
      background-color: white;
      position: sticky;
      // width: 200px;
      top: 200px;

      .el-menu--inline {
        overflow: hidden;

        .el-menu-item {
          padding-left: 50px !important;
        }
      }

      .el-menu-item,
      .el-submenu {
        span {
          // margin-left: 10px;
        }
      }
    }

    .is-active {
      &::before {
        position: absolute;
        left: 0;
        top: calc((100% - 35px) / 2);
        content: "";
        display: block;
        width: 5px;
        height: 35px;
        background-color: $--themeColor;
        box-shadow: none !important;
        border: none !important;
      }
    }

    .el-tree {
      border: 1px solid #d9d9d9;
      height: max-content;
      background-color: white;
      position: sticky;
      top: 200px;
      box-sizing: border-box;
      padding: 20px 50px;

      > .el-tree-node {
        &:first-child {
          > .el-tree-node__content {
            margin-top: 0;
          }
        }

        > .el-tree-node__content {
          font-weight: bold;
        }
      }

      .el-tree-node__expand-icon {
        display: none;
      }

      .el-tree-node__label {
        font-size: 18px;
        color: #333333;
      }

      .el-tree-node__content {
        margin-top: 20px;
      }
    }
  }
}

.page {
  background: #f2f2f2;

  > .dkzc-box {
    display: flex;
    flex-direction: column;
    width: 1020px;
    margin: 0 auto;
    margin-top: 30px;
    // margin-left: 13vw;

    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-bottom: 22px;
    }

    > img {
      margin: 22px 0 30px 0;
    }

    .studyPlan-button {
      cursor: pointer;
      z-index: 1;
      position: absolute;
      font-size: 18px;
      color: #ffffff;
      width: 75px;
      height: 36px;
      background: $--themeColor;
      border-radius: 5px;
      text-align: center;
      line-height: 12px;
      right: 50px;
      top: -45px;
    }

    .titleInput {
      display: flex;
      flex-direction: column;
      padding: 30px 50px;
      box-sizing: border-box;

      > .titleOne {
        font-size: 24px;
        color: #333;
        margin-top: 20px;
        border: 0px;
        outline: none;
        border-bottom: 2px solid $--themeColor;
        padding-bottom: 10px;
        box-sizing: border-box;
        margin-bottom: 20px;
      }

      > textarea {
        height: 184px;
        border: 0px;
        outline: none;
        resize: none;
        font-size: 14px;
        color: #8f8f8f;
      }

      > .textButton {
        cursor: pointer;
        width: 170px;
        height: 50px;
        background: $--themeColor;
        border: 1px solid $--themeColor;
        border-radius: 26px;
        color: #ffffff;
        line-height: 50px;
        text-align: center;
      }
    }

    > .analogVolume {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      width: 1020px;
      border-radius: 4px;
      font-size: 24px;
      color: #333333;
      margin: 0 auto;

      > .tab {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        border-bottom: 1px solid #d9d9d9;

        > .tabAnalogvolume {
          display: block;
          margin: 0 50px 10px 30px;
          color: $--themeColor;
        }
      }

      .personal {
        padding: 30px 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        position: relative;

        > .information {
          display: flex;
          flex-direction: row;
          align-items: center;

          > .avatar {
            width: 84px;
            height: 84px;
            object-fit: cover;
            border-radius: 50%;
          }

          > .information-right {
            display: flex;
            flex-direction: column;
            margin-left: 20px;

            > .right-one {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 24px;
              color: #333333;

              > .jobTitle {
                font-size: 13px;
                color: $--themeColor;
                width: 75px;
                height: 25px;
                border: 1px solid $--themeColor;
                border-radius: 6px;
                text-align: center;
                line-height: 25px;
                margin-left: 12px;
              }
            }

            > .right-two {
              font-size: 16px;
              margin-top: 10px;

              > .address {
                margin-right: 20px;
              }
            }

            > .right-three {
              font-size: 12px;
              margin-top: 10px;
              color: #8f8f8f;
            }
          }
        }

        > .profession {
          margin-top: 30px;
          width: 100%;
          /*段落中文本不换行*/
          white-space: nowrap;
          /*设置横向滚动*/
          overflow-x: auto;
          /*禁止纵向滚动*/
          overflow-y: hidden;
          /*文本平铺*/
          text-align: justify;
          padding: 0px 5px;
          margin-bottom: 10px;
          /*设置边距改变效果为内缩*/
          box-sizing: border-box;

          > span {
            &:last-child {
              margin-right: 0;
            }

            width: 100px;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 9px;
            text-align: center;
            line-height: 40px;
            margin-right: 10px;
            padding: 9px 16px;
            font-size: 14px;
            color: #8f8f8f;
            cursor: pointer;

            &.active {
              background: $--themeColor;
              color: #ffffff;
              border: 1px solid $--themeColor;
            }
          }
        }

        > .personal-studyPlan {
          display: flex;
          flex-direction: row;

          > .studyPlan-right {
            width: 570px;
            display: flex;
            flex-direction: column;
            height: 500px;
            overflow-y: auto;
            overflow-x: hidden;

            > .right-box {
              display: flex;
              flex-direction: column;
              width: 570px;
              background: #fafafa;
              padding: 19px 30px 20px 25px;
              box-sizing: border-box;
              font-size: 16px;
              margin-bottom: 10px;
              position: relative;

              &:last-child {
                margin-bottom: 0;
              }

              > .right-top {
                display: flex;
                flex-direction: row;

                > span {
                  display: block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                > .shenglvehao {
                  font-weight: bold;
                  margin-left: auto;
                }
              }

              > .right-subTitle {
                font-size: 12px;
                color: #8f8f8f;
                margin-top: auto;
                line-height: 1.8;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }

        > .learningTrack-one {
          display: flex;
          flex-direction: row;
          align-items: center;

          .learningTrackLeft {
            display: flex;
            align-self: baseline;
            min-width: 280px;
          }

          .learningTrack-avatar {
            width: 84px;
            height: 84px;
            object-fit: cover;
            border-radius: 50%;
          }

          .learningTrack-right {
            display: flex;
            flex-direction: column;
            color: #333333;
            font-size: 16px;
            margin: 0 30px 0 20px;

            > .learningTrack-time {
              font-size: 24px;
              margin-top: 16px;
              flex: 1;
            }
          }

          .progressBarBox {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            flex: 1;

            .progressBar {
              position: relative;
              margin-right: 30px;

              .progressBar-profession {
                position: absolute;
                font-size: 8px;
                top: 46px;
                left: 16px;
                // left: 50%;
                // margin-left: -41px;
                width: 50px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
              }
            }
          }
        }

        > .learningTrack-course {
          display: flex;
          flex-direction: row;
          margin-top: 40px;
          padding: 0 50px;
          box-sizing: border-box;
          flex-wrap: wrap;
          justify-content: space-between;

          > .course-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #333333;
            font-size: 18px;
            width: 478px;
            overflow: hidden;
            margin-top: 40px;

            // &:nth-child(-n + 2) {
            //   margin-top: 0;
            // }

            > .content-title {
              font-weight: 600;
              margin-bottom: 13px;
            }

            > .courseDetails {
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
              font-size: 16px;
              overflow: hidden;
              white-space: nowrap;
              margin-bottom: 16px;
              cursor: pointer;

              &:last-child {
                margin-bottom: 0;
              }

              > .border {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                background: #ffffff;
                border: 2px solid #8f8f8f;
                border-radius: 50%;
                margin-right: 14px;

                > .border-round {
                  width: 8px;
                  height: 8px;
                  background: #8f8f8f;
                  border-radius: 50%;
                }
              }

              .border1 {
                border: 2px solid $--themeColor;

                .border-round1 {
                  background: $--themeColor;
                }
              }

              > .point {
                flex: 1;
                overflow: hidden;
                margin-left: 7px;
                margin-right: 2px;
              }

              > .courseDetails-time {
                color: #8f8f8f;
                margin-right: 14px;
              }
            }
          }
        }
      }

      .interval {
        height: 20px;
        background: #f2f2f2;
        width: 1020px;
      }

      .content {
        background: #ffffff;
        padding: 30px;
        box-sizing: border-box;
        color: #333333;
        position: relative;

        > .video {
          width: 82px;
          height: 100%;
          border-radius: 10px;
          position: absolute;
          right: 0;
          top: 0;

          > .img {
            cursor: pointer;
            position: sticky;
            top: 84%;
            width: 82px;
            height: 82px;
            overflow: hidden;
            border-radius: 10px;
            background: #000;
            display: flex;
            justify-content: center;
            align-items: center;

            > img {
              object-fit: cover;
              width: 82px;
              height: 82px;
              -webkit-mask: -webkit-linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              );
            }

            > .el-icon-caret-right {
              position: absolute;
              font-size: 24px;
              color: white;
              margin-bottom: 20px;
              margin-top: 20px;
            }

            > span {
              position: absolute;
              margin-top: 20px;
              font-size: 14px;
              color: white;
            }
          }
        }

        > .professionalContent {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 50px;

          &:last-child {
            margin-bottom: 0;
          }

          > .content-title {
            font-size: 18px;
            color: $--themeColor;
            width: 120px;
            height: 40px;
            background: #dbfbf8;
            border-radius: 20px;
            text-align: center;
            line-height: 40px;
            margin-bottom: 10px;
          }

          > .learn-title {
            font-size: 24px;
            margin: 10px 0;
          }

          > .chapter {
            font-size: 18px;
            margin: 10px 0;
          }

          > .subTitle {
            font-size: 14px;

            /deep/ {
              p,
              span,
              h1,
              h2,
              h3,
              h4,
              h5 {
                line-height: 1.8 !important;
                font-size: 18px !important;
              }
            }

            text-indent: 50px;
          }
        }
      }
    }
  }
}
</style>
